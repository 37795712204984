<template>
    <v-row justify="space-around">
        <v-col  cols="12" md="5" >
            <div class="employee-vacations-year-item-container">
                <v-avatar
                :color="color"
                size="56"
                ></v-avatar>
                <div class="employee-vacations-item-texts-container">
                    <span class="employee-vacations-item-title">
                        {{yearNumber}}º año laboral {{titleConcat}}
                    </span>
                    <span class="employee-vacations-item-subtitle">
                        {{takenDays}}/{{vacationDaysAvailable}} días tomados
                    </span>
                    <v-progress-linear 
                        :value="percentageDays"
                        :color="color"
                        background-color="#707070"
                    ></v-progress-linear>

                </div>

            </div>
        </v-col>
    </v-row>
    

</template>
<script>
export default {
    props: ['color', 'yearNumber', 'takenDays', 'percentageDays', 'vacationDaysAvailable', 'titleConcat'],
    model: {
        
    },
    computed: {
       
    }
}
</script>

<style lang="scss">
    .employee-vacations{



        &-year-item-container{
            display: flex;
            gap: 10px;
            align-items: center;
        }

        

        &-item-texts-container{
            display: flex;
            flex-direction: column;
            width: -webkit-fill-available;
        }

        &-item-title{
            font-size: 0.65rem;
        }

        &-item-subtitle{
            font-size: 0.77rem;
            margin-bottom: 5px;
        }
    }
</style>
<template>
	<v-card height="100vh" width="100%" flat tile class="card-container" :img="image">
		<v-container fluid class="pa-0" >
			<v-row no-gutters dense class="fill-height" justify="center">
				<v-col sm="12" md="7" lg="5" xl="4" class="" >
					<v-card color="transparent" class="pa-0 d-flex justify-center align-center flex-column align-start" tile flat height="100vh">

						<v-container fluid class="container d-flex justify-center" >
							<v-row justify="center" align-content="center" no-gutters>
								<v-col sm="12" align-self="center">
									<v-card width="72%" class=" login-container mx-auto d-flex justify-center flex-column form__full-container" height="" tile flat >
										
										<div  class="d-flex justify-center flex-column form__logo-container" no-gutters>
											
											<v-img contain width="210" class="mx-auto" :src="logo"></v-img>
											
										</div>
										
										<v-row v-show="loginError">
											<v-col class="text-center">
												<span class="red--text">{{ errorText }}</span>
											</v-col>
										</v-row>
										<v-row justify="center" class="mt-10" no-gutters>
											<v-col cols="10" class="">
												<v-form ref="form" v-model="valid" lazy-validation v-if="login">
													<v-card tile flat color="transparent">
														<v-card-text class="py-0">
															<v-row>
																<v-col class="py-0 px-0">
																	<v-text-field
																		color="black"
																		hide-details
																		v-model="user"
																		
																		v-on:keyup.native.enter="loginApi"
																		class="with-line input"
																	>
																		<template v-slot:label>
																			<span class="input__label">Correo Electronico</span>
																		</template>
																	</v-text-field>
																</v-col>
															</v-row>
															<v-row>
																<v-col class="py-0 px-0">
																	<v-text-field
																		color="black"
																		hide-details
																		v-model="password"
																		
																		:type="show ? 'text' : 'password'"
																		label=""
																		
																		@click:append="show = !show"
																		v-on:keyup.native.enter="loginApi"
																		class="with-line "
                                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                                        
																	>
																		<template v-slot:label>
																			<span class="input__label ">Contraseña</span>
																		</template>
																	</v-text-field>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
													
													<v-row class="mt-5">
														<v-col class="py-10 text-center">
															<v-btn :loading="loginIn" color="primary" block light depressed @click="loginApi" class="form__btn">
																<div class="btn__text" >
																	Iniciar sesión
																</div>
															</v-btn>
															<!-- Olvidé mi contraseña link -->
															<div class="my-9 form__btn-link">
																<a href="/login" class="">&nbsp;</a>
															</div>
														</v-col>
													</v-row>
												</v-form>
												
											</v-col>
										</v-row>
										
										<div class="triangle-login triangle-login-1"></div>
										<div class="triangle-login triangle-login-2"></div>
										<div class="triangle-login triangle-login-3"></div>
										
									</v-card>
								</v-col>
								
							</v-row>
							<br>
							
						</v-container>
						
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
		
	
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
import store from '../store';
import TokenManager from '../helpers/TokenManager';
import background from '../assets/back_2.png';
import logo from '../assets/logo-merca.svg';
// import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data: () => ({
        image: background,
		logo: logo,
		login: true,
		loginIn: false,
		valid: false,
		show: false,
		rememberMe: false,
		user: '',
		password: '',
		loginError: false,
		errorText: '',
		rules: {
			required: value => !!value || 'Correo requerido',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Correo no válido'
			},
			password: [
				v => !!v || 'Contraseña requerida'
			],
		},

	}),
	methods: {
		loginApi(){
			if(this.$refs.form.validate()){
				this.loginIn = true
				this.loginError = false
				//const tokenManager = new TokenManager();

				this.$api.auth.login(this.user, this.password)
				.then((response) =>{
					
					console.log(response)                
					if(response.status == 200 || response.status == 201){
						const type = 'Bearer'
						const token = response.data.access_token
						TokenManager.setToken(type+' '+token);
						this.$api.auth.reloadToken();
                        console.log(store)
						//localStorage.setItem('access_token_rrhh', type+' '+token)
						// store.state.loggedIn = false;
						// store.state.layout = 'dashboard';
                        //store.state.userData = response.data;
                        //VueGatesPlugin.init();
						//store.state.loggedUsername = response.data.username;
						this.$router.push('/');
                        
					}else{
						TokenManager.removeToken();
						console.log('si llego')
						this.loginError = true
						switch (response.data.error) {
							case 'invalid_grant':
								this.errorText = 'Usuario y/o contraseña incorrectos'
								break;
							default:
								this.errorText = 'Error en servidor. Intente nuevamente.'
								break;
						}
					}
				})
				.catch((error) =>{
					TokenManager.removeToken();
					console.log(error, "error desde login");
					this.loginError = true
					if (error.response) {
						if(error.response.status == 401){
							this.errorText = 'Usuario y/o contraseña incorrectos';
							return;
						}
					}
					this.errorText = 'Error en servidor. Intente nuevamente.'
				})
				.finally(() => {
					this.loginIn = false
				});
				
			}
		},
        
	}
}
</script>

<style lang="scss">
	// .v-text-field--outlined fieldset {
	// 	color: red !important;
	// }

	.input{
		
		&__label{
			color: var(--v-loginText-base)
		}
	}
	.with-line > .v-input__control > .v-input__slot:before {
		border-style: solid !important; 
		border-color: #0e0e0e !important;
	}

	.btn{
		&__text{
			font-size: 1.0625rem;
			font-weight: bold;
			color: var(--v-loginText-base);
			text-transform: uppercase;
		}
	}

	.form{

		&__full-container{
			background-color: rgba(0, 0, 0, 0.100) !important;
			z-index: 1;
			overflow: hidden;
		}
		&__logo-container{
			min-height: 12.5rem;
		}

		&__btn{

			border-radius: 0.75rem !important;
		
			&-link{
			
			
				> a{
					text-decoration: none;
					color: #001660 !important;
				}
			}
		}
	}

	.container{
		height: inherit;
	}

	.card-container{
		overflow: auto;
	}

	.login-container{

		@media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
			transform: scale(1.2);
			transform-origin: 1 0;
		}
	}

	

	.triangle-login{
        width: 100%;
        height: 21.875rem;
        background: unset;
        position: absolute;
        bottom: 0;
        left: 0px;
        z-index: -1;
		
        &::after{
            content: "";
            width: 120%;
            height: 15.62rem;
            position: absolute;
            left: 0;
			bottom: 0;
            z-index: -500;
            -webkit-clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);
    
        }

        &-1{
            &::after{
                background: rgba(0, 0, 0, 0.050) !important;
				left: -10% !important;
            }
			
        }

        &-2{
            &::after{
                background: rgba(0, 0, 0, 0.050) !important;
				height: 10rem;
				width: 80%;
				left: 10%;
				
            }
        }

        &-3{
            &::after{
                background: rgba(0, 0, 0, 0.050) !important;
                height: 4.6875rem;
				width: 40%;
				left: 30%;
            }
        }

    }
</style>
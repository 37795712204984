<template>
	<div class="app-container">
        <v-row justify="space-around" >
                    <v-col cols="12" md="5" >
                        <SwipeButton-rl
                            ref="swipeButtonrl"
                            class="swipe-button"
                            @actionConfirmed="onActionConfirmed"
                            initialInstructionText="Cancelar asistencia"
                            sliderBackgroundColor="#FFB40A"
                            textColor="#444444"
                            overlayColor="#FFB40A"
                            buttonColor="#FFB40A"
                            
                        ></SwipeButton-rl>
                    </v-col>
                </v-row>
	</div>
</template>

<script lang="ts">
    // import VueGatesPlugin from '../plugins/vue-gates';
	export default {
		name: 'Blank',
		components: {
			
		},
        computed: {
            routeName: {
                get: function() {
                    if(this.$route.name != 'Login')
                        return true;
                    return false;
                }
               
            },
            
        
        },
        mounted(){
            //VueGatesPlugin.init();
            //console.log(this.$route)
        },
        methods: {
            onActionConfirmed() {
                setTimeout(() => {
                    this.$refs.swipeButtonrl.reset();
                }, 1000);
            },
        },
	}
</script>

<style lang="scss">

	
</style>
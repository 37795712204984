
import BaseApi from "../BaseApi";

export default class EmployeeVacationService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get items
     */
    async list()
    {
        return this.http.get(`${this.baseUrl}/app/employees/vacations`)
        .catch(this.handleErrorResponse);
    }

    async createRequest(data)
    {
        return this.http.post(`${this.baseUrl}/app/employees/vacations`, data)
        .catch(this.handleErrorResponse);
    }

    async confirm(id)
    {
        return this.http.get(`${this.baseUrl}/app/employees/vacations/${id}/accept`)
        .catch(this.handleErrorResponse);
    }

    



}